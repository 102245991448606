<template>
  <EntityList
    ref="list"
    :config="config"
    :columns="columns"
    :filter-vals="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    :results-per-page="25"
    report-view
    authority="Measurements_Measurement"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [null, null, null, 'METERS'],
      dockingOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'measurements',
        endpoint: 'measurements',
        route: 'measurements',
        filtersOpen: true,
        title: {
          single: this.$i18n.t('Measurement'),
          plural: this.$i18n.t('Measurements'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'samplingTime', label: this.$t('time'), sortable: true },
        {
          key: 'p1',
          label: this.$i18n.t('rawTh', {
            name: 'P1',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p2',
          label: this.$i18n.t('rawTh', {
            name: 'P2',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p3',
          label: this.$i18n.t('rawTh', {
            name: 'P3',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p4',
          label: this.$i18n.t('rawTh', {
            name: 'P4',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p5',
          label: this.$i18n.t('rawTh', {
            name: 'P5',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p6',
          label: this.$i18n.t('rawTh', {
            name: 'P6',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's1',
          label: this.$i18n.t('rawTh', {
            name: 'S1',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's2',
          label: this.$i18n.t('rawTh', {
            name: 'S2',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's3',
          label: this.$i18n.t('rawTh', {
            name: 'S3',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's4',
          label: this.$i18n.t('rawTh', {
            name: 'S4',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's5',
          label: this.$i18n.t('rawTh', {
            name: 'S5',
            unit: this.meterUnit,
          }),
        },
        {
          key: 's6',
          label: this.$i18n.t('rawTh', {
            name: 'S6',
            unit: this.meterUnit,
          }),
        },
        {
          key: 'p1Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P1',
            unit: this.cmUnit,
          }),
        },
        {
          key: 'p2Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P2',
            unit: this.cmUnit,
          }),
        },
        {
          key: 'p3Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P3',
            unit: this.cmUnit,
          }),
        },
        {
          key: 'p4Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P4',
            unit: this.cmUnit,
          }),
        },
        {
          key: 'p5Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P5',
            unit: this.cmUnit,
          }),
        },
        {
          key: 'p6Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'P6',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's1Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S1',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's2Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S2',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's3Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S3',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's4Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S4',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's5Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S5',
            unit: this.cmUnit,
          }),
        },
        {
          key: 's6Retwist',
          label: this.$i18n.t('retwistTh', {
            name: 'S6',
            unit: this.cmUnit,
          }),
        },
      ]
    },
    formattedColumns() {
      return [{ key: 'samplingTime', type: 'datetime' }]
    },
    meterUnit() {
      return this.filterVals[3] == 'FEET' ? 'feet' : 'm'
    },
    cmUnit() {
      return this.filterVals[3] == 'FEET' ? 'feet' : 'cm'
    },
    filters() {
      return [
        {
          name: 'docking',
          label: this.$t('Docking'),
          options: this.dockingOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$t('Start Date Edit'),
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$t('End Date Edit'),
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
        {
          name: 'measurementunit',
          options: [
            { id: 'METERS', title: 'Meters' },
            { id: 'FEET', title: 'Feet' },
          ],
          label: this.$t('Measurement Unit'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchDockings').then((response) => {
      this.dockingOptions = response.data
    })
  },
}
</script>
